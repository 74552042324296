<template>
  <div>
    <v-radio-group
      v-model="timeFilterType"
      :column="column"
      :row="row"
      class="mt-0 pt-0 align-start"
      dense
      hide-details
    >
      <div :class="['d_flex', 'align-center', row ? 'mr-4' : 'mb-3']">
        <v-radio :disabled="disableAllTimeInput" :value="1" class="mb-0 mr-0">
        </v-radio>
        <v-select
          v-model="shortedTimeSelected"
          :disabled="disableAllTimeInput"
          :items="TIME_OPTIONS"
          :menu-props="{ nudgeTop: '-27px' }"
          append-icon="mdi-menu-swap"
          class="text-body-1 mt-0"
          dense
          hide-details
          item-text="name"
          item-value="value"
          placeholder="Chọn giá trị"
          @change="onSelectShortedTime"
        ></v-select>
      </div>

      <div class="d_flex align-center">
        <v-radio :value="2" class="mb-0 mr-0"></v-radio>
        <v-menu
          ref="menuRef"
          v-model="isVisibleMenu"
          :close-on-content-click="false"
          :return-value.sync="selectedDates"
          min-width="290px"
          offset-x
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              :class="{
                'input-date--customize': dateRangeText !== 'Tùy chỉnh'
              }"
              background-color="white"
              class="rounded-lg text-body-1 grey--text text--darken-4 mt-0"
              dense
              flat
              hide-details
              readonly
              single-line
              style="width: 180px;"
              v-bind="attrs"
              v-on="on"
            >
              <template slot="append">
                <v-icon>mdi-calendar</v-icon>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="selectedDates"
            :max="disableFuture ? new Date().toISOString().substr(0, 10) : null"
            :min="disablePast ? new Date().toISOString().substr(0, 10) : null"
            color="primary"
            no-title
            range
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="isVisibleMenu = false">
              Hủy
            </v-btn>
            <v-btn color="primary" text @click="onUpdateCustomizeTime">
              Lọc
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
    </v-radio-group>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from "vue";
import { isArray } from "@thinkvn/utils";
import { TIME_OPTIONS } from "./utils";

const DATE_TYPE = {
  KEY: 1,
  CUSTOMIZE: 2
};

export default defineComponent({
  props: {
    column: { type: Boolean, default: true },
    disableFuture: { type: Boolean, default: false },
    disableAllTimeInput: { type: Boolean, default: false },
    disablePast: { type: Boolean, default: false },
    row: { type: Boolean, default: false },
    value: { type: [String, Array], default: null }
  },

  setup(props, { emit }) {
    const menuRef = ref();
    const isVisibleMenu = ref(false);

    const timeFilterType = ref(
      isArray(props.value) ? DATE_TYPE.CUSTOMIZE : DATE_TYPE.KEY
    );

    const shortedTimeSelected = ref(
      isArray(props.value) ? "full-time" : props.value
    );

    const selectedDates = ref(isArray(props.value) ? props.value : []);
    const dateRangeText = computed(() => {
      if (selectedDates.value.length < 2) return "Tùy chỉnh";

      return `${selectedDates.value[0]} - ${selectedDates.value[1]}`;
    });

    const onUpdateCustomizeTime = () => {
      timeFilterType.value = DATE_TYPE.CUSTOMIZE;

      // reset data time rút gọn
      shortedTimeSelected.value = "full-time";

      menuRef.value.save(selectedDates.value);

      onUpdateValue({
        value: selectedDates.value,
        type: timeFilterType.value
      });
    };

    const onSelectShortedTime = () => {
      timeFilterType.value = DATE_TYPE.KEY;

      // reset data time customize
      selectedDates.value = [];

      onUpdateValue({
        value: shortedTimeSelected.value,
        type: timeFilterType.value
      });
    };

    watch(timeFilterType, value => {
      onUpdateValue({
        value:
          value === DATE_TYPE.KEY
            ? shortedTimeSelected.value
            : selectedDates.value,
        type: value
      });
    });

    const onUpdateValue = data => {
      emit("change", data);
      emit("input", data.value);
    };

    return {
      DATE_TYPE,

      menuRef,
      isVisibleMenu,

      shortedTimeSelected,
      TIME_OPTIONS,
      onSelectShortedTime,

      selectedDates,
      dateRangeText,
      onUpdateCustomizeTime,

      timeFilterType
    };
  }
});
</script>

<style lang="scss" scoped>
.item-hover {
  cursor: pointer;
}

.input-date--customize {
  font-size: 13px !important;
}
</style>
